const daanoeMenu = () => {
  const homepage = document.querySelector('body.home');

  const navBar = document.querySelector('header.nav-bar');
  const mobileNav = document.querySelector('.nav-bar-mobile');

  const keepVisibleColumn = document.querySelector('.keep-visible-toggle');
  const keepVisibleToggles = keepVisibleColumn.querySelectorAll('i');

  if (!homepage) {
    navBar.classList.add('keep-visible');
    keepVisibleColumn.parentNode.removeChild(keepVisibleColumn);
  } else {
    mobileNav.classList.add('fixed');
  }

  if(keepVisibleToggles) {
    keepVisibleToggles.forEach((icon) => {
      icon.addEventListener('click', () => {
        navBar.classList.toggle('keep-visible');
      });
    });
  }
};

export default daanoeMenu;
