const artworksJS = () => {
    jQuery(document).ready(function(){

        const initGallery = () => {
            jQuery('#artwork-grid').justifiedGallery({
                captions: false,
                margins: 5,
                rowHeight: 270,
                waitThumbnailsLoad: false,
            });
        };


        initGallery();

        var scroll = null;

        jQuery(document).on('js_event_wpv_pagination_completed', () => {
            // scroll positie vasthouden
            scroll = jQuery(window).scrollTop();
            initGallery();

        });

        jQuery('#artwork-grid').justifiedGallery().on('jg.complete', function (e) {
            if (scroll) {
                setTimeout(() => {
                    jQuery('html').scrollTop(scroll);
                }, 600);


            }
        });


        jQuery('input.check_as_radio').on('change', function() {
            var category = jQuery(this).data('category');
            jQuery(`input.check_as_radio[data-category='${category}']`).not(this).prop('checked', false);
        });


        const setWindowOrientation = () => {

            if(window.innerHeight > window.innerWidth){
                jQuery('body').addClass('orientation-portrait');
                jQuery('body').removeClass('orientation-landscape');
            } else {
                jQuery('body').removeClass('orientation-portrait');
                jQuery('body').addClass('orientation-landscape');
            }

        };

        setWindowOrientation();

        window.addEventListener('resize', () => {
            setWindowOrientation();
        });

    });


    /**
     * Get a URL query parameter value
     * @param sParam
     * @returns {*}
     */
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };


};

export default artworksJS;
